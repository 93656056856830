const Section12 = () => {
  return (
    <div className="mt-10 mx-10 grid grid-cols-1 items-center justify-center gap-[18px] text-center text-8xl text-brand-color-primary font-inter">
      <div className="grid grid-cols-1 md:grid-cols-2 place-items-center">
        <img
          className="max-w-full md:h-full h-[231px] md:pr-10 relative rounded-3xs overflow-hidden max-h-[260px] shrink-0 object-cover"
          alt=""
          src="/first@2x.png"
        />
        <div className="flex flex-col">
          <div>
            <b className="md:pl-400 pt-10 flex items-center justify-center">
              Find the answers to anything you need in our Help Center
            </b>
          </div>
          <div className="flex flex-col md:flex-row gap-10">
            <div className="bg-color-white md:mt-16 shadow-[0px_3px_4px_rgba(0,_0,_0,_0.1)] h-[100px] font-semibold flex flex-wrap text-base justify-center items-center flex-col px-3">
              <p className="m-0">How does Startifyre</p>
              <p className="m-0">Startup management system work?</p>
            </div>
            <div className="text-white md:mt-16 bg-cornflowerblue shadow-[0px_3px_4px_rgba(0,_0,_0,_0.1)] h-[100px] font-semibold flex flex-wrap text-base justify-center items-center flex-col px-3">
              <p className="m-0">What does Startifyre</p>
              <p className="m-0">pluggable modules do?</p>
            </div>
          </div>
        </div>
      </div>
      <a
        href="https://wa.me/+971524624739"
        target="_blank"
        className="no-underline"
      >
        <div className="flex flex-col items-center justify-center">
          <button className="cursor-pointer text-xl [border:none] p-3 text-white px-10 bg-[transparent] rounded-41xl [background:linear-gradient(264.48deg,_#79f1a4,_#5bacf2)] shadow-[0px_5px_22.58px_rgba(50,_174,_137,_0.25)]">
            Call Us
          </button>
        </div>
      </a>
    </div>
  );
};

export default Section12;
