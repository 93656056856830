import { useMemo } from "react";

const ComponentCard = ({
  photo,
  subtitle,
  title,
}) => {
  return (
    <div className="relative rounded-xl bg-color-white shadow-[0px_28px_33px_rgba(0,_0,_0,_0.1)] h-auto flex flex-col py-[22px] px-[17px] box-border items-center justify-start gap-[13px] text-center text-5xl text-brand-color-primary font-inter">
      <div className="flex flex-col items-center justify-center">
        <img
          className="relative rounded-3xs max-w-full overflow-hidden max-h-[260px] shrink-0 object-cover transition duration-500 ease-in-out hover:scale-110"
          alt=""
          src={photo}
        />
      </div>
      <b className="relative leading-[103.69%]">
        <p className="m-0">{title}</p>
      </b>
      <div className="relative text-mini leading-[22px] text-darkslategray text-justify inline-block shrink-0">
        {subtitle}
      </div>
    </div>
  );
};

export default ComponentCard;
