import { useMemo } from "react";
import ReactGA from "react-ga4";

const Socials = ({ linkedin, instagram, facebook, socialsPosition }) => {
  const socialsStyle = useMemo(() => {
    return {
      position: socialsPosition,
    };
  }, [socialsPosition]);

  const buttonClick = (title) => {
    console.log(`Footer clicked ${title}`);
    ReactGA.event({
      category: "button",
      action: `button clicked ${title}`,
      label: `${title} footer button clicked`,
      value: 99,
      nonInteraction: true,
      transport: "xhr",
    });
  };

  return (
    <div
      className="relative overflow-hidden flex flex-row flex-wrap items-center justify-start gap-[16px]"
      style={socialsStyle}
    >
      <a
        href="https://www.linkedin.com/company/startifyre"
        target="_blank"
        onClick={() => buttonClick("LinkedIN")}
      >
        <img
          className="relative w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0"
          alt=""
          src="/linkedin.svg"
        />
      </a>
      <a
        href="https://www.instagram.com/startifyre/"
        target="_blank"
        onClick={() => buttonClick("Instagram")}
      >
        <img
          className="relative w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0"
          alt=""
          src="/instagram.svg"
        />
      </a>
      <a
        href="https://www.facebook.com/startifyre"
        target="_blank"
        onClick={() => buttonClick("Facebook")}
      >
        <img
          className="relative w-[1.5rem] h-[1.5rem]"
          alt=""
          src="/facebook.svg"
        />
      </a>
    </div>
  );
};

export default Socials;
