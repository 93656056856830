import ComponentCard from "./ComponentCard";

const Section4Card = () => {
  return (
    <div id="platformfeatures" className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-2 gap-6 md:pl-40 md:pr-40">
      <ComponentCard
        photo="/startup-starter-pack.png"
        title="Startup Starter Pack"
        subtitle="
        Your essential toolkit for launching with confidence. Gain product clarity with tailored technical and design specifications, plus an initial architecture design to lay the groundwork for success. Let&apos;s build something incredible together.
        "
      />
      <ComponentCard
        photo="/Startup-0-to-1.png"
        title="Startup 0 to 1"
        subtitle="Turn your idea into a thriving business with our complete solution. From clarity and initial architecture design to building your product and support transitioning, we&apos;ve got you covered. Together, we build, you own."
      />
    </div>
  );
};

export default Section4Card;
