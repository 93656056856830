import Socials from "./Socials";
import React from "react";
import ReactGA from "react-ga4";

const MobileFooterHome = () => {
  const buttonClick = (title) => {
    console.log(`Footer clicked ${title}`);
    ReactGA.event({
      category: "button",
      action: `button clicked ${title}`,
      label: `${title} footer nav-button clicked`,
      value: 99,
      nonInteraction: true,
      transport: "xhr",
    });
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-10 bg-gray-200 pb-10 pt-10 pl-5 mt-8 overflow-hidden box-border items-start justify-between text-left text-smi text-color-gray-4 font-inter">
      <div className="flex flex-col items-start justify-start gap-[20px] md:pl-10">
        <img alt="" src="/logo.png" width="166" height="85" />
        <div className="flex text-start justify-start w-60  leading-[20px]">
          <p className="text-justify">
            we're dedicated to empowering your business success through tailored software solutions focused on scalability, robust architecture, and efficient team management.
          </p>
        </div>
        <div className="flex flex-col items-start justify-start gap-[17px]">
          <div className="relative text-xl leading-[30px] font-medium text-steelblue">
            Contact Us
          </div>
          <a
            target="_blank"
            className="text-white no-underline"
            href={`mailto:contact@startifyre.com`}
          >
            <div
              className="relative leading-[26px]"
              onClick={() => buttonClick("Email")}
            >
              Email: contact@startifyre.com
            </div>
          </a>
          <a
            target="_blank"
            className="text-white no-underline"
            href="https://wa.me/+971524624739"
            onClick={() => buttonClick("Phone")}
          >
            <div className="relative leading-[26px]">Call: +971 524624739</div>
          </a>
        </div>
        <Socials
          linkedin="/linkedin1.svg"
          instagram="/instagram1.svg"
          facebook="/facebook1.svg"
          socialsPosition="unset"
        />
      </div>
      <div id="contactForm" className="card h-fit max-w-6xl p-5 md:p-12 pr-10">
        <h2 className="mb-4 text-2xl font-bold dark:text-white">Ready to Get Started?</h2>
        <form id="contactForm" action="https://submit-form.com/fRlsciMEy">
          <div className="mb-6">
            <div className="mx-0 mb-1 sm:mb-4">
              <div className="mx-0 mb-1 sm:mb-4">
                <label for="name" className="pb-1 text-xs uppercase tracking-wider">
                </label>
                <input required type="text" id="name" autocomplete="given-name" placeholder="Your name" className="mb-2 w-full rounded-md border border-gray-400 py-3 pl-3 pr-4 shadow-md dark:text-gray-300 sm:mb-0 text-lg" name="name" />
              </div>
              <div className="mx-0 mb-1 sm:mb-4">
                <label for="email" className="pb-1 text-xs uppercase tracking-wider">
                </label>
                <input required type="email" id="email" autocomplete="email" placeholder="Your email address" className="mb-2 w-full rounded-md border border-gray-400 py-3 pl-3 pr-4 shadow-md dark:text-gray-300 sm:mb-0 text-lg" name="email" />
              </div>
            </div>
            <div className="mx-0 mb-1 sm:mb-4">
              <label for="textarea" className="pb-1 text-xs uppercase tracking-wider">
              </label>
              <textarea required id="textarea" name="textarea" cols="30" rows="5" placeholder="Write your message..." className="mb-2 w-full rounded-md border border-gray-400 py-3 pl-3 pr-4 shadow-md dark:text-gray-300 sm:mb-0 text-lg"></textarea>
            </div>
          </div>
          <div className="text-center">
            <button type="submit" className="w-full bg-blue-800 text-white px-6 py-3 font-xl rounded-md sm:mb-0">Send Message</button>
          </div>
        </form>
      </div>
      {/* <div className="mt-10 shrink-0 grid grid-cols-2 md:grid-cols-4 gap-[10px] text-left text-smi text-color-white font-inter">
        <div className="flex flex-col items-start justify-start gap-[17px]">
          <div className="relative text-xl leading-[30px] font-medium text-steelblue">
            Services
          </div>
          <div
            className="relative leading-[26px]"
            onClick={() => buttonClick("Customers")}
          >
            Customers
          </div>
          <div
            className="relative leading-[26px]"
            onClick={() => buttonClick("Collaborators")}
          >
            Collaborators
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[17px]">
          <div className="relative text-xl leading-[30px] font-medium text-steelblue">
            Company
          </div>
          <div
            className="relative leading-[26px]"
            onClick={() => buttonClick("About Us")}
          >
            About Us
          </div>
          <div
            className="relative leading-[26px]"
            onClick={() => buttonClick("Our Team")}
          >
            Our Team
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[17px]">
          <div className="relative text-xl leading-[30px] font-medium text-steelblue">
            Support
          </div>
          <div
            className="relative leading-[26px]"
            onClick={() => buttonClick("FAQs")}
          >
            FAQs
          </div>
          <div
            className="relative leading-[26px]"
            onClick={() => buttonClick("Privacy Policy")}
          >
            Privacy Policy
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-[17px]">
          <div className="relative text-xl leading-[30px] font-medium text-steelblue">
            Contact Us
          </div>
          <a
            target="_blank"
            className="text-white no-underline"
            href={`mailto:contact@startifyre.com`}
          >
            <div
              className="relative leading-[26px]"
              onClick={() => buttonClick("Email")}
            >
              Email: info@startifyre.com
            </div>
          </a>
          <a
            target="_blank"
            className="text-white no-underline"
            href="https://wa.me/+971524624739"
            onClick={() => buttonClick("Phone")}
          >
            <div className="relative leading-[26px]">Call: +971 524624739</div>
          </a>
        </div>
      </div> */}
    </div>
  );
};

export { MobileFooterHome };
