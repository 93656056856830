import React, { useState } from 'react';

const TestimonialSlider = () => {
  const testimonials = [
    {
      quote: "We aimed to boost our team's productivity, and Startifyre assisted us in conducting a comprehensive analysis of our operational limitations. Their suggestions resulted in substantial process enhancements, leading to improved efficiency. Furthermore, their insights enabled us to anticipate and tackle potential scalability issues in our core modules. This proactive approach has been crucial in averting potential challenges. We highly recommend their services as they are exceptional at what they do.",
      author: "Faisal",
      position: "CTO, Exatorial",
      image: "/Exatorial.jpg"
    },
    {
      quote: "We subscribed to Startifyre's Startup Starter Pack and it was a game-changer for Cloud Siesta. Their team expertly guided us in identifying the right tools and technologies, provided clarity on our product and features, and developed an initial design that perfectly aligned with our vision. Additionally, their comprehensive work estimation helped us plan effectively for the future. Startifyre's support has been invaluable in setting a strong foundation for our startup.",
      author: "Pradip Dhakal",
      position: "CEO, CloudSiesta",
      image: "/CloiudSiesta.jpeg"
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <section style={{ textAlign: 'center', backgroundColor: '#ffffff', padding: '40px' }} id="carousel_ced9">
      <header>
        <h2 style={{ fontSize: '2.5rem', marginBottom: '30px', color: '#000000' }}>What Our Clients Say</h2>
      </header>
      <div style={{ margin: '0 auto', maxWidth: '1000px' }}>
        <div id="carousel-3580" data-interval="5000" style={{ position: 'relative', width: '100%' }}>
          <div style={{ display: 'flex', overflow: 'hidden', width: '100%', justifyContent: "center" }}>  {/* Adjusted width to 100% for proper wrapping */}
            {testimonials.map((testimonial, index) => (
              <div key={index} style={{ flex: '0 0 100%', textAlign: 'center', paddingLeft: '50px', display: index === currentIndex ? 'block' : 'none' }}>
                <div style={{ border: '10px solid #333', borderRadius: '50%', width: '200px', height: '200px', margin: '0 auto', overflow: 'hidden' }}>
                  <img src={testimonial.image} alt={testimonial.author} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
                <p style={{ paddingLeft: '30px', paddingRight: '100px', fontSize: '1.3rem', fontStyle: 'italic', margin: '30px 0',color: '#333333', whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>"{testimonial.quote}"</p>
                <p style={{ fontSize: '1.2rem', fontWeight: 'bold', color: '#333333', color: "#5bacf2" }}>{testimonial.author}</p>
                <p style={{ fontSize: '1rem', color: '#333333', marginTop: "-10px" }}>{testimonial.position}</p>
              </div>
            ))}
          </div>
          <a style={{ position: 'absolute', top: '60%', left: '0', right: "10", transform: 'translateY(-50%)', cursor: 'pointer', }} onClick={handlePrev} role="button" data-u-slide="prev">
            <span aria-hidden="true">
              <svg viewBox="0 0 477.175 477.175" style={{ width: '40px', height: '40px', fontWeight: "bolder" }}><path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z" fill="#00008B"></path></svg>
            </span>
            <span style={{ display: 'none' }}>Previous</span>
          </a>
          <a style={{ position: 'absolute', top: '60%', right: '0', transform: 'translateY(-50%)', cursor: 'pointer' }} onClick={handleNext} role="button" data-u-slide="next">
            <span aria-hidden="true">
              <svg viewBox="0 0 477.175 477.175" style={{ width: '40px', height: '40px' }}><path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z" fill="#00008B"></path></svg>
            </span>
            <span style={{ display: 'none' }}>Next</span>
          </a>
          <ol style={{ display: 'flex', marginLeft: "40px", marginTop: "-10px", justifyContent: 'center', listStyle: 'none', padding: 0 }}>
            {testimonials.map((_, index) => (
              <li key={index} data-u-target="#carousel-3580" style={{ margin: '5px', cursor: 'pointer', width: '15px', height: '15px', borderRadius: '50%', backgroundColor: index === currentIndex ? '#333' : '#ccc', textAlign: 'center' }} data-u-slide-to={index}></li>
            ))}
          </ol>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSlider;
