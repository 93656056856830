import React from "react";
import ReactGA from "react-ga4";

const Section2 = () => {
  const buttonClick = () => {
    ReactGA.event({
      category: "button",
      action: "Request a demo button clicked",
      label: "Request a demo button home",
      value: 99,
      nonInteraction: true,
      transport: "xhr",
    });
  };

  return (
    <div className="w-full mt-16 grid grid-cols-1 md:grid-cols-2 gap-[34px] text-left px-2 overflow-auto text-42xl text-brand-color-primary font-inter">
      <div className="flex flex-col pt-0 px-0 box-border items-left justify-center">
        <div className="flex px-5 flex-col items-left justify-center">
          <b className="" style={{ fontSize: "35px" }}>
            Unleash the potential of your startup
          </b>
          <div className="text-lgi mt-5 leading-[30px] text-gray-100 flex items-center shrink-0">
          At Startifyre, we are dedicated to providing tailored software solutions that drive your business success. With a focus on scalable system design and architecture, as well as effective management of engineering teams and processes, we offer expert services to help your business thrive.
          </div>
        </div>
        <div className="text-mini leading-[28px] px-5 mt-5 text-darkslategray flex items-left shrink-0">
        Explore our range of services below to see how we can help you streamline your operations, enhance your systems, and empower your business growth.
        </div>
        <a
          href="#contactForm"
          className="no-underline flex item-start md:justify-start justify-center mt-5"
        >
          <div className="rounded-41xl [background:linear-gradient(264.48deg,_#79f1a4,_#5bacf2)] shadow-[0px_5px_22.58px_rgba(50,_174,_137,_0.25)] p-3 py-4 md:ml-4 flex flex-col items-center justify-center text-center text-lg text-color-white">
            <div
              className="relative font-medium inline-block w-[171.09px] h-[26.83px] shrink-0"
              onClick={() => buttonClick()}
            >
              Request a Demo
            </div>
          </div>
        </a>
      </div>
      <div className="flex item-center">
        <img className="max-w-full h-auto" alt="" src="/right1@2x.png" />
      </div>
    </div>
  );
};

export default Section2;
