import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./global.css";

const container = document.getElementById("root");
const root = createRoot(container);
import {
    FpjsProvider
  } from '@fingerprintjs/fingerprintjs-pro-react'

root.render(
  <BrowserRouter>
    <FpjsProvider
      loadOptions={{
        apiKey: "93D213Z7CXSWxThGOjPQ",
        region: "ap",
      }}
    >
      <App />
    </FpjsProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
