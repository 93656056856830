import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import Signup from "./pages/Signup";
import Mobile from "./pages/Mobile";
import ReactGA from "react-ga4";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";

import OpenReplay from "@openreplay/tracker";

ReactGA.initialize("G-CKTMVVE2LD");

//...
const tracker = new OpenReplay({
  projectKey: "tlFv9GrekfuD4ueQQVvW",
});
tracker.start();

const App = () => {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const { isLoading, error, data, getData } = useVisitorData(
    { extendedResult: true },
    { immediate: true }
  );

  useEffect(() => {
    if (data?.visitorId) {
      tracker.setUserID(data?.visitorId);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Mobile />} />
      {/* <Route path="/signup" element={<Signup />} /> */}
    </Routes>
  );
};
export default App;
