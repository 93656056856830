import Logo from "./Logo";
import ReactGA from "react-ga4";
import React from "react";

const MobileHeader = () => {
  const menuClick = (title) => {
    console.log(`Button clicked ${title}`);
    ReactGA.event({
      category: "button",
      action: "button clicked",
      label: `${title} button clicked`,
      value: 99,
      nonInteraction: true,
      transport: "xhr",
    });
  };
  return (
    <div style={{ background: "#c6d9ea" }}>
      <div className="ml-5 flex flex-row items-center justify-between mr-4" >
        <a href="/" className="no-underline">
          <img className="" alt="" src="/logo.png" width="166" height="85" style={{marginTop: "10px"}} />
        </a>
        <div className="flex flex-col justify-between md:flex-row md:gap-5 md:justify-center md:items-center text-center">
          <a
            href="/"
            className="no-underline text-black hover:text-blue-500 hidden md:block"
            onClick={() => menuClick("Home")}
          >
            <p className="font-inter">Home</p>
          </a>
          <a
            href="#platformfeatures"
            className="no-underline text-black hover:text-blue-500 hidden md:block"
            onClick={() => menuClick("Platform Features")}
          >
            <p className="font-inter">Services</p>
          </a>
          <a
            href="#contactForm"
            className="no-underline text-black hover:text-blue-500 hidden md:block"
            onClick={() => menuClick("Contact Us")}
          >
            <p className="font-inter">Contact Us</p>
          </a>
          {/* <a
          href="/signup"
          className="no-underline text-black hover:text-blue-500 hidden md:block"
          onClick={() => menuClick("Pricing")}
        >
          <p className="font-inter">Pricing</p>
        </a>
        <a href="/signup" className="no-underline">
          <div className="rounded-41xl bg-cornflowerblue overflow-hidden shrink-0 flex flex-row flex-wrap py-[9px] px-4 box-border items-center justify-between">
            <div
              onClick={() => menuClick("Signup")}
              className="relative font-semibold text-white"
            >
              Sign Up
            </div>
          </div>
        </a> */}
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
