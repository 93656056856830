import ComponentCard from "./ComponentCard";

const Section5Card = () => {
  return (
    <div id="platformfeatures" className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 md:pl-40 md:pr-40">
      <ComponentCard
        photo="/Productivity-and-Scalability-Review.png"
        title="Productivity and Scalability Review"
        subtitle="
        Streamline operations, enhance performance, and boost overall productivity. Let our experts provide a comprehensive, actionable report outlining key improvement areas."
      />
      <ComponentCard
        photo="/project-outsourcing.png"
        title="Projects Outsourcing"
        subtitle="Empower your business with exceptional results on complex projects. We handle the complexities while you focus on driving your business forward."
      />
      <ComponentCard
        photo="/Elastic-Team.png"
        title="Elastic Team"
        subtitle="For pilot projects, complex architectures, or sudden surges in requirements, our Elastic Team offers a swift solution. Start within a week and detach at your convenience."
      />
      <ComponentCard
        photo="/Team-Building-and-Mentorship.png"
        title="Team Building and Mentorship"
        subtitle="We excel in assembling top talent tailored to your business needs and culture, ensuring a robust foundation for success."
      />
        <ComponentCard
        photo="/pluggable-modules.png"
        title="Pluggable Modules"
        subtitle="Unlock the potential of your business with customizable, seamless integration across your systems. Choose from a variety of modules tailored to your needs."
        checklist={[
            "Strategic Hiring",
            "Employee Mentorship",
            "Engineering Management"
          ]}
      />
      <ComponentCard
        photo="/llm-integration.png"
        title="AI & Machine Learning"
        subtitle="Enhance your business with custom AI chatbots that act as customer care and sales assistants. Leverage machine learning to optimize operations and boost critical metrics."
      />
    </div>
  );
};

export default Section5Card;
