import Section12 from "../mobile-components/Section12";
import Section4Card from "../mobile-components/Section4Card";
import Section2 from "../mobile-components/Section2";
import MobileHeader from "../mobile-components/Mobileheader";
import { MobileFooterHome } from "../mobile-components/Footer";
import { CardGrid } from "../mobile-components/section";
import { FeatureComponent } from "../mobile-components/faeture-component";
import { ContactUsForm } from "../mobile-components/contactUs";
import Section5Card from "../mobile-components/section5card";
import TestimonialSlider from "../mobile-components/section13";
// import TiltImage from "/background-image.png"

const Mobile = () => {
  return (
    <div className="bg-color-white w-full overflow-hidden text-center text-lg text-color-white font-inter">
      <MobileHeader />
      <Section2 />
      <br/>
      <br />
      <div className="mt-10 relative flex flex-col">
        <img src="/background-tilt.png" className="w-full" style={{ height: "250px"}} />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center">
            <span className="relative text-white leading-none font-inter text-[2rem] md:text-[4rem] md:leading-none inline-block pl-5 pr-5">
            Our Services and Offerings
            </span>
          </div>
        </div>
      </div>
      <br />
      <div className="flex flex-row items-center mt-10 text-11xl text-brand-color-primary">
        <div className="md:w-1/5 hidden md:block" />
        <div className="w-full md:w-3/5">
          <b className="relative inline-block pl-5 pr-5">
          For new startups
          </b>
          <br />
          <div className="relative px-1 text-lg pb-10 leading-[28px] font-medium text-brand-color-primary inline-block pt-5">
          Getting off the ground
          </div>
          {/* <div className="relative px-1 text-lg pb-10 leading-[28px] font-medium text-gray-100 inline-block pt-5">
            Enhancing guest experiences and optimizing your operations like
            never before
          </div> */}
        </div>
        <div className="md:w-1/5 hidden md:block" />
      </div>
      <br />
      
      <Section4Card />
      <br />
      <br />
      <div className="flex flex-row items-center mt-10 text-11xl text-brand-color-primary py-5">
        <div className="md:w-1/5 hidden md:block" />
        <div className="w-full md:w-3/5">
          <b className="relative inline-block pl-5 pr-5">
          For established startups
          </b>
          <br />
          <div className="relative px-1 text-lg pb-10 leading-[28px] font-medium inline-block pt-5 text-brand-color-primary">
            Streamline and optimize
          </div>
        </div>
        <div className="md:w-1/5 hidden md:block" />
      </div>
      <br />
      <br />
      <Section5Card />
      {/* <div className="flex skewY-5 text-center justify-center align-middle mt-10 pt-[70px] pb-[70px] [background:linear-gradient(264.48deg,_#79f1a4,_#5bacf2)] shadow-[0px_5px_22.58px_rgba(50,_174,_137,_0.25)]">
        <div className="md:w-1/5 hidden md:block" />
        <div className="w-full md:w-3/5">
          <b className="relative inline-block pl-5 pr-5">
            Where modern innovation and thoughtful guest experiences come
            together to create a transformative hospitality journey
          </b>
        </div>
        <div className="md:w-1/5 hidden md:block" />
      </div> */}

      {/* background image on text */}
      <div className="tilt"></div>

      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-full"
        height="262"
        viewBox="0 0 1650 262"
        fill="none"
      >
        <path d="M0 0H1440V222.13L0 262V0Z" fill="#C4C4C4" />
        <path
          d="M0 0H1440V222.13L0 262V0Z"
          width="100%"
          fill="url(#paint0_linear_305_1450)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_305_1450"
            x1="-100"
            y1="262"
            x2="1650.99"
            y2="587.331"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#5BACF2" />
            <stop offset="1" stop-color="#169571" />
          </linearGradient>
        </defs>
      </svg> */}

      {/* <CardGrid
        order="last"
        image="/right@2x.png"
        title="Unlock Your Business's Potential"
        subtitle="Discover Efficiency and Growth with Our Solutions"
        description="Supercharge your business with our premade pluggable modules—super customizable and scalable solutions designed to unlock your full potential. Seamlessly integrate them across your systems and choose from our diverse array of modules."
        checklist={[
          "Authentication Integration",
          "Billing Management",
          "Payment Gateway Integration",
          "CRM",
          "Campaign Manager",
          "Inventory Manager",
          "Order Manager"
        ]}
      />
      <CardGrid
        image="/ai-powered-channel-manager-3@2x.png"
        title="Team Building and Mentorship"
        subtitle="Empower Your Team for Success"
        description="We provide comprehensive support in hiring and mentoring your employees, ensuring you have the right people to drive your business forward. Our services include strategic hiring, employee mentorship, and engineering management:"
        checklist={[
          "Strategic Hiring",
          "Employee Mentorship",
          "Engineering Management"
        ]}
      />
      <CardGrid
        order="last"
        image="/ai-powered-channel-manager-4@2x.png"
        title="For New Startups"
        subtitle="Get Your Startup Off the Ground"
        description="Startifyre offers tailored solutions to help new startups thrive:"
        checklist={[
          "Startifyre Startup Starter pack",
          "Productivity and Scalability Review",
          "Startifyre 0 to 1 pack",
        ]}
      />
      <CardGrid
        image="/photo6@2x.png"
        title="For Running Companies"
        subtitle="Fuel Growth for Established Ventures"
        description="Startifyre provides essential services to support running startups:"
        checklist={[
          "Elastic Teams",
          "Projects Outsourcing",
          "Hiring, Management, and Mentoring",
          "Pluggable Modules"
        ]}
      /> */}
      <Section12 />
      {/* <Section13 /> */}
      <br />
      <br />
      <TestimonialSlider />
      
      {/* <ContactUsForm /> */}
      <MobileFooterHome />
    </div>
  );
};

export default Mobile;
